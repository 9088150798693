.App {
  text-align: center;
  background-image: url(./background.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.App-header {
  min-height: 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-body {
  height: calc(100svh - 96px);
  color: white;
  font-family: Inder;
}

.App-link {
  color: #61dafb;
}

@font-face {
  font-family: Inder;
  src: url(./Inder-Regular.ttf);
}

.Acceuil-Tx {
  font-size: 2.63rem;
  margin-bottom : 0;
  margin-top: 0;
  padding: 0
}

.Acceuil-Tx2 {
  font-size: 3.95rem;
  margin-top: 0;
  margin-bottom: 1;
  padding: 0
}

.imgTech {
  max-width: 8%;
}
